<template>
  <div class="title-box">
    <div @click="$router.back()">
      <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
    </div>
    <img
      src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/work-title-icon.png"
      alt=""
    />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.title-box{
  width: 1237px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-top: 68px;
  padding-bottom: 21px;
  .backIcon{
    font-size: 26px;
    margin-right: 12px;
  }
  img{
    height: 19px;
  }
}
</style>