<template>
  <div class="operationHomeWork" v-loading="uploadLoading" :element-loading-text="uploadProgress">
    <workTopBack />
    <div class="content">
      <p class="title">{{ homeworkInfo?.name }}</p>
      <!-- 提交作业后显示信息 -->
      <!-- status 0 未提交 1草稿 2 已提交 3 未通过 4 通过 -->
      <template v-if="homeworkUserInfo?.status === 4">
        <div class="info">
          <p>
            <span class="label">来自：</span>
            <span class="val">{{ subUserInfo.name }}</span>
          </p>
          <p>
            <span class="label"> 时间：</span>
            <span class="val">{{ createTime }}</span>
          </p>
        </div>
      </template>
      <div class="describe">
        <p class="label">作业要求：</p>
        <p class="des">{{ homeworkInfo?.remark }}</p>
      </div>
      <div class="attachment">
        <p class="label">附件：</p>
        <fileList :list="attachmentList" :is-download="true"></fileList>
      </div>
      <!-- 有审批结果显示tab -->
      <template v-if="[2,3,4].includes(homeworkUserInfo?.status)">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="作业内容" name="1"></el-tab-pane>
          <el-tab-pane label="审批结果" name="2"></el-tab-pane>
        </el-tabs>
      </template>
      <!-- 作业内容 -->
      <template v-if="activeName === '1'">
        <div class="workContentBox">
          <div class="workStepBox">
            <div class="workStepItem" v-for="(item, index) in workList" :key="index + 'work'">
              <div class="step">
                <p class="step-title">第{{ index + 1 }}步</p>
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/work-del-icon.png" v-if="(index + 1 > 1) && [0,1,3].includes(homeworkUserInfo.status)"
                  @click="del(index, '', 'step')" class="delIcon" alt="">
              </div>
              <p class="line"></p>
              <div class="workContent">
                <el-input type="textarea" :disabled="[2,4].includes(homeworkUserInfo?.status)" :rows="4" maxlength="1200" show-word-limit placeholder="请用文字描述操作步骤（选填）最多输入1200字"
                  v-model="item.remark">
                </el-input>
                <div class="uploadFileBox">
                  <p class="label">上传附件：</p>
                  <div class="uploadContainer">
                    <!--  -->
                    <template v-if="[0,1,3].includes(homeworkUserInfo.status)">
                      <div class="uploadBtn" @click="handleUploadBtn(item,index)">
                        <i class="el-icon-plus"></i>
                        <span>本地上传</span>
                      </div>
                      <p class="tip">（支持图片、音视频、文档、压缩包、最多支持5个附件）</p>
                    </template>
                    <fileList :list="item.fileList" :status="homeworkUserInfo.status" :className="'uploadFileListBox'" :class-item-name="'uploadFileList'" :is-del="true"></fileList>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="[0,1,3].includes(homeworkUserInfo.status)">
            <div class="addBtn" @click="addStep">
              <i class="el-icon-plus"></i>
              <span>添加步骤</span>
            </div>
            <div class="button-box">
              <el-button type="info" @click="$router.back()" round>取消</el-button>
              <el-button type="primary" @click="saveDraft" round>保存</el-button>
              <el-button type="primary" @click="submitHomeWork" round>提交</el-button>
            </div>
          </template>
        </div>
      </template>
      <!-- 审批结果 -->
      <template v-else>
        <approvalProcess :approve-process="approvalProcessList" />
      </template>

    </div>
    <!-- 上传图片/scorm -->
    <!-- <uploadOss ref="uploadFileAll" :fileCount="fileCount" :fileNum="5" :maxSize="1024 * 1024 * 500" @file-obj="getFileObjPic" /> -->
    <UploadResources ref="uploadFileAll" @file-obj="getFileObjPic" @upload-progress="handleUploadProgress"/>
  </div>
</template>

<script>
import UploadResources from '../components/UploadResources.vue'
import workTopBack from '../components/workTopBack.vue';
import fileList from '../components/fileList';
import approvalProcess from '../components/approval-process';
export default {
  components: { 
    UploadResources, workTopBack, fileList, approvalProcess 
  },
  computed:{
    subUserInfo(){
      return this.$store.state.subUserInfo
    }
  
  },
  data() {
    return {
      uploadProgress:'0%',
      uploadLoading:false,
      fileCount:0,// 已上传文件数
      activeName: '1',
      createTime:'',
      workList: [
        {
          remark: '',
          fileList: []
        }
      ],
      
      approvalProcessList: [],
      attachmentList: [], //附件
      saveData: {
        taskId: '',// 任务id
        homeworkId: '',//作业id
        content: '',// 作业提交内容 json串
      },
      homeworkUserInfo: {}, // 作业用户提交信息
      homeworkInfo: {},// 作业附件信息
      taskId: '',
      homeworkId: ''
    }
  },
  created() {
    this.homeworkId = this.$route.query.id || '';
    this.taskId = this.$route.query.taskId || '';
    this.getDetail()
  },
  methods: {
    handleUploadProgress(val){
      this.uploadProgress = val
    },
    // 删除作业步骤和文件
    del(index) {
      this.workList.splice(index, 1)
    },
    
    // 获取作业详情
    getDetail() {
      const params = {
        homeworkId: this.homeworkId,
        taskId: this.taskId
      }
      this.$api.operationHomeWork.getDetail({ params }).then(res => {
        console.log('res', res);
        if (res.data) {
          // 附件
          this.attachmentList = res.data.homeworkInfo.annex && JSON.parse(res.data.homeworkInfo.annex);
          if(res.data.homeworkUserInfo.status){
            // 作业
            this.workList = res.data.homeworkUserInfo.content && JSON.parse(res.data.homeworkUserInfo.content)
          }
          this.approvalProcessList = res.data.approvalRecords || []; // 审批结果
          this.createTime = this.approvalProcessList.length && this.approvalProcessList[this.approvalProcessList.length-1].createTime
          this.approvalProcessList.forEach(item => {
            item.fileList = item.annex && JSON.parse(item.annex) || [];
          })
          this.homeworkUserInfo = res.data.homeworkUserInfo || {};
          this.homeworkInfo = res.data.homeworkInfo || {};
        }
      })
    },
    // 提交作业
    submitHomeWork() {
      this.saveData = {
        taskId: this.taskId,
        homeworkId: this.homeworkId,
        content: JSON.stringify(this.workList)
      }
      const data = {
        ...this.saveData
      }
      this.$api.operationHomeWork.submitHomeWork({ data }).then(res => {
        if (res.success) {
          this.$message.success('提交成功');
          this.$router.go(-1)
        }
      })
    },
    // 保存
    saveDraft() {
      this.saveData = {
        taskId: this.taskId,
        homeworkId: this.homeworkId,
        content: JSON.stringify(this.workList)
      }
      const data = {
        ...this.saveData
      }
      this.$api.operationHomeWork.saveDraft({ data }).then(res => {
        if (res.success) {
          this.$message.success('保存成功');
          this.$router.go(-1)
        }

      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    addStep() {
      if(this.workList.length >= 5){
        this.$message.warning('最多支持添加5步')
        return false;
      }
      this.workList.push({
        remark: '',
        fileList: []
      })
    },
    handleUploadBtn(item,index) {
      this.uploadProgress = '0%'
      if (item.fileList.length >= 5) {
        this.$message.warning('最多支持上传5个附件')
        return false;
      }
      this.fileCount = item.fileList.length;
      this.currentIndex = index;
      this.$refs.uploadFileAll.handleClick();
     
    },
    getFileObjPic(file) {
      this.workList[this.currentIndex].fileList.push({
        url: file.url,
        name: file.name,
        type: file.type,
        size:file.size,
        id: file.id,
        _type:file._type
      })
      console.log('file', file);

    }
  }
}
</script>

<style lang="scss" src="../assets/index.scss" scoped></style>