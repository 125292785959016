<template>
    <input type="file" :accept="accept" ref="file" @change="handleChange" style="visibility: hidden" />
</template>

<script>
import TcVod from "vod-js-sdk-v6";
export default {
    name: 'UploadResources',
    methods: {
        handleClick() {
            this.$refs.file.click();
        },
        handleChange(e) {
            console.log('====================================');
            console.log(e.target.files);
            console.log('====================================');
            let isVideo = /^video\//
            let isAudio = /^audio\//
            let isApplication = /^application\//

            let fileList = e.target.files
            if(!fileList.length) return
            this.$parent.uploadLoading = true;
            fileList.forEach(file => {
                let type = file.type
                if (isVideo.test(type)) {
                    console.log('视频')
                    this.handleUploadVideo(file)
                } else if (isAudio.test(type)) {
                    console.log('音频')
                    this.handleUploadAudio(file)
                } else if (isApplication.test(type)) {
                    console.log('文档')
                    this.handleUploadApplication(file)
                } else {
                    console.log('其他')
                    this.handleUploadOss(file)
                }
            })
        },
        // 上传进度
        uploadProgress(percent) {
            let text = parseInt(percent*100)+'%'
            this.$emit('upload-progress', text)
        },
        // 上传成功
        uploadSucceed(fileInfo) {
            const { file, fileType, fileId } = fileInfo
            const lastIndex = file.name.lastIndexOf('.');
            const prefixName = file.name.substring(0, lastIndex);
            const suffixType = file.name.substring(lastIndex + 1);
            const formData = new FormData();
            formData.append('business', 1);
            formData.append('fileType', fileType);
            formData.append('name', file.name);
            formData.append('videoId', fileId);

            this.$api.resource.uploadBusinessFile(formData).then((res) => {
                if (res.success) {
                    const { url, id } = res.data
                    const data = {
                        url, id,
                        name: prefixName,
                        type: suffixType,
                        size: file.size,
                        _type: file.type
                    }
                    this.$emit('file-obj', data);
                }
            }).finally(()=>{
                this.$parent.uploadLoading = false;
            })
        },
        // 上传失败
        uploadFailed(errInfo) {
            console.log('====================================');
            console.log(errInfo);
            console.log('====================================');
            this.$message.error('上传失败');
            this.$parent.uploadLoading = false;
        },
        // 上传视频
        handleUploadVideo(file) {
            this.uploader = this.createTcUploader(file);
            this.uploader.done().then((doneResult) => {
                const fileObj = {
                    file,
                    fileType: 1,
                    fileId: doneResult.fileId
                }
                this.uploadSucceed(fileObj)
            }).catch((err) => {
                this.uploadFailed(err)
            })
        },
        // 腾讯vod上传初始化
        createTcUploader(mediaFile) {
            this.tcVod = new TcVod({
                getSignature: () => {
                    return this.$api.resource.getUploadVideoSignature({ fileName: mediaFile.name, fileType: 1 }).then(function (response) {
                        return response.data.sign
                    });
                }
            });
            const uploader = this.tcVod.upload({
                mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
                chunkSize: 1048576, //分片大小默认1 MB，不能小于100 KB
                chunkParallelLimit: 5, // 并行上传分片个数，默认5
                chunkRetryTimes: 2, // 网络原因失败时，重新上传次数，默认为2
                progressInterval: 500 //上传进度的回调方法 onProgress 的回调频率
            });
            uploader.on('media_progress', (info) => {
                this.uploadProgress(info.percent)
            })
            return uploader;
        },
        // 上传音频
        handleUploadAudio(file) {
            this.uploader = this.createAliUploader();
            this.uploader.addFile(
                file,
                null,
                null,
                null,
                '{"Vod":{}}',
            );
            this.uploader.startUpload();
        },
        // 阿里vod上传初始化
        createAliUploader() {
            const self = this;
            // eslint-disable-next-line no-undef
            const uploader = new AliyunUpload.Vod({
                timeout: 600000,
                partSize: 1048576,  //分片大小默认1 MB，不能小于100 KB
                parallel: 5,  // 并行上传分片个数，默认5
                retryCount: 3, // 网络原因失败时，重新上传次数，默认为3
                retryDuration: 2,  // 网络原因失败时，重新上传间隔时间，默认为2秒
                userId: 1923859927839944,
                addFileSuccess() {

                },
                onUploadstarted(uploadInfo) {
                    console.log('====================================');
                    console.log('satartUpload-------', uploadInfo);
                    console.log('====================================');
                    if (!uploadInfo.videoId) {
                        self.$api.resource.getUploadVideoParam(`fileName=${uploadInfo.file.name}&fileType=${2}`).then(({ data }) => {
                            const { uploadAuth } = data;
                            const { uploadAddress } = data;
                            const { videoId } = data;
                            uploader.setUploadAuthAndAddress(
                                uploadInfo,
                                uploadAuth,
                                uploadAddress,
                                videoId,
                            );
                        });
                    } else {
                        self.$api.resource.refreshUploadVideo(`videoId=${uploadInfo.videoId}`).then(({ data }) => {
                            const { uploadAuth } = data;
                            const { uploadAddress } = data;
                            uploader.setUploadAuthAndAddress(
                                uploadInfo,
                                uploadAuth,
                                uploadAddress,
                                // videoId,
                            );
                        });
                    }
                },
                onUploadSucceed(uploadInfo) {
                    console.log('====================================');
                    console.log('uploadSucceed-------', uploadInfo)
                    console.log('====================================');
                    const fileObj = {
                        file: uploadInfo.file,
                        fileType: 2,
                        fileId: uploadInfo.videoId
                    }
                    self.uploadSucceed(fileObj);
                },
                onUploadFailed(uploadInfo) {
                    self.uploadFailed(uploadInfo);
                },
                onUploadCanceled(uploadInfo) {
                    console.log('====================================');
                    console.log('uploadCanceled-------', uploadInfo);
                    console.log('====================================');
                },
                onUploadProgress(uploadInfo, totalSize, loadedPercent) {
                    console.log('====================================');
                    console.log('uploadProgress-------', uploadInfo, totalSize, loadedPercent);
                    console.log('====================================');
                    self.uploadProgress(loadedPercent)
                },
                onUploadTokenExpired(uploadInfo) {
                    console.debug('onUploadTokenExpired')
                    self.$api.resource.refreshUploadVideo(`videoId=${uploadInfo.videoId}`).then(({ data: { result } }) => {
                        const uploadAuth = result.upload_auth;
                        uploader.resumeUploadWithAuth(uploadAuth);
                    });
                },
                onUploadEnd() {
                    console.log('====================================');
                    console.log('uploadEnd');
                    console.log('====================================');
                },
            });

            return uploader;
        },
        // 上传文档
        handleUploadApplication(file) {
            const lastIndex = file.name.lastIndexOf('.');
            const prefixName = file.name.substring(0, lastIndex);
            const suffixType = file.name.substring(lastIndex + 1);
            const formData = new FormData();
            formData.append('business', 1);
            formData.append('fileType', 3);
            formData.append('file', file);
            this.$api.resource.uploadBusinessFile(formData).then((res) => {
                if (res.success) {
                    const { url, id } = res.data
                    const data = {
                        url, id,
                        name: prefixName,
                        type: suffixType,
                        size: file.size,
                        _type: file.type
                    }
                    this.$emit('file-obj', data);
                }
            }).finally(()=>{
                this.$parent.uploadLoading = false;
            })
        },
        // 图片、zip、scorm上传到oss
        async handleUploadOss(file) {
            const api = 'getAliyunDocConf';
            this.datas = await this.$api.operationHomeWork[api]();
            this.initOSSClient(this.datas);
            const fileType = "to-convert-doc";
            const path = this.creatFileUrl(file.name)
            const uploadUrl = `${this.datas.data.filePathPrefix}${fileType}/${path}`;
            const lastIndex = file.name.lastIndexOf('.');
            const prefixName = file.name.substring(0, lastIndex);
            const suffixType = file.name.substring(lastIndex + 1);
            this.ossClient
                .multipartUpload(uploadUrl, file, {
                    progress: (p) => {
                        this.uploadProgress(p)
                    }
                })
                .then((result) => {
                    const temp = {
                        size: file.size,
                        url: `https://${this.datas.data.privateBucket}.${this.datas.data.endpoint}/${result.name}`,
                        bucketName: this.privateBucket,
                        id: JSON.parse(result.etag),
                        urlTrue: result.name,
                        type: suffixType,
                        name: prefixName,
                        _type: file.type
                    };
                    this.$emit("file-obj", temp);
                })
                .catch((err) => {
                    console.debug("Resume multipart upload failed === ", err);
                }).finally(()=>{
                    this.$parent.uploadLoading = false;
                })
        },
        // 创建OSS Client
        initOSSClient(datas) {
            const OSS = require("ali-oss");
            this.bucketName = datas.data.privateBucket;
            this.ossClient = new OSS({
                region: datas.data.region,
                accessKeyId: datas.data.accessKeyId,
                accessKeySecret: datas.data.accessKeySecret,
                bucket: this.bucketName /* 装图片的桶名 */,
                stsToken: datas.data.securityToken,
                endpoint: datas.data.endpoint, // 新增加
            });
        },
        creatFileUrl(name) {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const timestamp = new Date().getTime();
            const fileSuffix = name.lastIndexOf(".");
            const fileExt = name.substring(fileSuffix); // 后缀名
            const storeAs = `${timestamp}${fileExt}`;
            return `${year}/${this.add0(month)}/${storeAs}`;
        },
        add0(m) {
            return m < 10 ? `0${m}` : m;
        },
    }
}
</script>

<style lang="scss" scoped></style>